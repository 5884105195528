import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Em, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				DriveAway Rentals
			</title>
			<meta name={"description"} content={"Досліджуйте нові дороги з легкістю"} />
			<meta property={"og:title"} content={"DriveAway Rentals"} />
			<meta property={"og:description"} content={"Досліджуйте нові дороги з легкістю"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,--color-primary 100%),url(https://uploads.quarkly.io/665d91718d4a0c00202fe404/images/2-1.jpg?v=2024-06-03T09:49:58.029Z) center">
			<Override slot="link" display="none" />
			<Override slot="text">
				Огляд наших послуг
			</Override>
			<Override slot="text1" color="--light">
				У DriveAway Rentals ми прагнемо надати вам винятковий досвід оренди автомобіля, пропонуючи широкий спектр послуг, спеціально розроблених для задоволення ваших потреб у подорожі. Наші послуги створені для того, щоб гарантувати, що кожен аспект вашої подорожі напрокат автомобіля буде бездоганним, зручним і налаштованим відповідно до ваших уподобань.
			</Override>
		</Components.Hero>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Дослідіть наш автопарк
				</Text>
				<Text font="--lead" color="--darkL2">
					Наш великий автопарк є основою високоякісного обслуговування, яке ми обіцяємо надавати. Кожен автомобіль у нашому автопарку ретельно відбирається та регулярно обслуговується для забезпечення безпеки, комфорту та надійності.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
				align-self="center"
				align-content="center"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="24px"
						max-width="100%"
						max-height="100%"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						height="100%"
						srcSet="https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1675311183084-755007dbb223?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Економічні автомобілі
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Ідеально підходить для бюджетних мандрівників, яким потрібна ефективність і легкість у навігації вулицями міста.
						</Text>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Позашляховики та мінівени
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Ідеально підходить для сімейних подорожей з достатнім простором для пасажирів і багажу.
						</Text>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Розкішні автомобілі
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							пропонує нотку елегантності та витончених функцій для особливих випадків.
						</Text>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Господарські транспортні засоби
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							включає пікапи та фургони, придатні для перевезення вантажів або групових поїздок.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Додаткові послуги
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Щоб покращити ваш досвід оренди, DriveAway Rentals пропонує ряд додаткових послуг, розроблених, щоб зробити вашу подорож більш гладкою та приємною.{"\n"}
							<br />
							<br />
							Пристрої GPS-навігації: Ніколи не заблукайте з нашими найсучаснішими пристроями GPS.
Дитячі крісла: Безпека та комфорт для малюків під час ваших подорожей.{"\n"}
							<br />
							<br />
							Пакети страхування транспортних засобів:  Різні варіанти покриття, щоб забезпечити душевний спокій, поки ви в дорозі.
Допомога на дорозі:  Цілодобова підтримка, щоб ви рухалися.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1543465077-db45d34b88a5?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Погодинна оренда: для коротких швидких поїздок по місту або між зустрічами.
							<br />
							<br />
							{"\n"}Подобова та тижнева оренда: ідеально підходить для відпочиваючих і ділових мандрівників.{"\n"}
							<br />
							<br />
							Довгострокова оренда: найкраще підходить для тривалих поїздок або тимчасової заміни автомобіля.
							<br />
							<br />
							{"\n"}Корпоративна оренда: Спеціалізовані послуги для підприємств, яким потрібна підтримка автопарку без клопоту щодо володіння транспортним засобом.
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Ідеально підходить для сімейних подорожей з достатнім простором для пасажирів і багажу.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Cta>
			<Override slot="text">
				<Strong>
					<Em>
						Готові дізнатися більше?
						<br />
					</Em>
				</Strong>
				{" "}Цей огляд — лише короткий огляд того, що ми пропонуємо в DriveAway Rentals. Щоб дізнатися про повний спектр наших послуг і про те, як ми можемо пристосувати наші пропозиції до ваших конкретних потреб, ми запрошуємо вас зв’язатися з нами або відвідати наш офіс.
			</Override>
		</Components.Cta>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});